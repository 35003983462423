export const SET_LOADING_FINISHED = 'LOADING_FINISHED';
export const INCREMENT_LOADING_PROGRESS = 'INCREMENT_LOADING_PROGRESS';
export const ADD_LOADING_ERROR = 'ADD_LOADING_ERROR';
export const SET_MAP_MODE = 'SET_MAP_MODE';
export const LOAD_WIRES_SOURCE_DATA = 'LOAD_WIRES_SOURCE_DATA';
export const LOAD_RISKS_SOURCE_DATA = 'LOAD_RISKS_SOURCE_DATA';
export const LOAD_MAPBOX_SETTINGS = 'LOAD_MAPBOX_SETTINGS';
export const UPDATE_RISK_LEVEL = 'UPDATE_RISK_LEVEL';
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';
export const UPDATE_VIEWPORT_PITCH = 'UPDATE_VIEWPORT_PITCH';
export const MOVE_TO_COORDINATES = 'MOVE_TO_COORDINATES';
export const UPDATE_OPERATIVE_COORDINATES = 'UPDATE_OPERATIVE_COORDINATES';
export const UPDATE_OPERATIVE_RISK_LEVEL = 'UPDATE_OPERATIVE_RISK_LEVEL';
export const SET_RISKS_AND_POWERLINES_LAYERS_OPACITY = 'SET_RISKS_AND_POWERLINES_LAYERS_OPACITY';
export const ADD_HIGHLIGHT_TO_RISK = 'ADD_HIGHLIGHT_TO_RISK';
export const REMOVE_HIGHLIGHT_FROM_RISK = 'REMOVE_HIGHLIGHT_FROM_RISK';
export const ADD_SELECTION_TO_RISK = 'ADD_SELECTION_TO_RISK';
export const REMOVE_SELECTION_FROM_RISK = 'REMOVE_SELECTION_FROM_RISK';
export const SWITCH_EXTRUSION_LAYER_ZERO_HEIGHT = 'SWITCH_EXTRUSION_LAYER_ZERO_HEIGHT';
