import React from 'react';
import { connect } from 'react-redux';

const COORD_DECIMALS = 6;

class InfoPanel extends React.Component {
  render() {
    const { lngLat, riskLevel } = this.props.operative;
    let lng, lat, riskPercent;

    if (lngLat[0] && lngLat[1]) {
      lat = lngLat[1].toFixed(COORD_DECIMALS);
      lng = lngLat[0].toFixed(COORD_DECIMALS);
      riskPercent = Math.round(riskLevel * 100);
    }

    return (
      <div className="panel info_section">
        <div className="info_section-item">
          <span className="info_section-item_label">LNG</span>
          {lng}
        </div>

        <div className="info_section-item">
          <span className="info_section-item_label">LAT</span>
          {lat}
        </div>

        <div className="info_section-item">
          <span className="info_section-item_label">RISK</span>
          {riskPercent ? riskPercent + "%" : ""}
        </div>
      </div>
    );
  }
}

const mapStateToInfoPanelProps = (state) => ({
  operative: state.operative
});

export default connect(mapStateToInfoPanelProps)(InfoPanel);
