import {
  UPDATE_OPERATIVE_COORDINATES,
  UPDATE_OPERATIVE_RISK_LEVEL
} from '../actionTypes';

export const updateOperativeCoordinates = (pointerEvent) => ({
  type: UPDATE_OPERATIVE_COORDINATES,
  payload: pointerEvent.lngLat
});

export const updateOperativeRiskLevel = (value) => ({
  type: UPDATE_OPERATIVE_RISK_LEVEL,
  payload: value
});
