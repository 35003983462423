import {
  UPDATE_VIEWPORT,
  ADD_SELECTION_TO_RISK,
  REMOVE_SELECTION_FROM_RISK
} from './store/actionTypes';
import queryString from 'query-string';

const QUERY_STRING_OPTIONS = {
  arrayFormat: 'comma',
  parseNumbers: true // as well as arrays
};

const UPDATE_URL_ACTIONS = [
  UPDATE_VIEWPORT,
  ADD_SELECTION_TO_RISK,
  REMOVE_SELECTION_FROM_RISK
];

// Redux middleware that tracks changes we want to reflect in the URL (such as
// where user is looking at the moment - viewport, or what risks are selected)
const urlUpdaterMiddleware = store => next => action => {
  const state = store.getState();
  const risks = state.map.sources.risks;

  let selectedRisksIDs = [];
  let fullViewport = {};

  if (UPDATE_URL_ACTIONS.indexOf(action.type) >= 0) {
    fullViewport = state.viewport;

    if (risks.features) {
      const selectedRisks = risks.features.filter(risk => risk.properties.selected === true);
      selectedRisksIDs = selectedRisks.map(risk => risk.id);
    }

    updateUrlParams(fullViewport, selectedRisksIDs);
  }

  return next(action);
}

export default urlUpdaterMiddleware;

// -- helpers

// Parses URL to retrieve params
// Example: http://localhost:3000/?token=example#bearing=0&latitude=-17.098080923122545&longitude=145.7739991611381&pitch=0&selectedRisks=32,33&zoom=20
export function parseUrlParams() {
  const hashParams = window.location.hash;

  return queryString.parse(hashParams, QUERY_STRING_OPTIONS);
}

// Collects and prepares params for query string
function updateUrlParams(fullViewport, selectedRisks) {
  const { longitude, latitude, bearing, pitch, zoom } = fullViewport;
  const thingsToUrl = { longitude, latitude, bearing, pitch, zoom, selectedRisks };

  if (longitude !== 0 && latitude !== 0) {
    const newQsValue = queryString.stringify(thingsToUrl, QUERY_STRING_OPTIONS);
    window.location.hash = newQsValue;
  }
}
