import React from 'react';
import Logo from './Logo';
import RisksList from './RisksList';
import Switch from './Switch';
import { connect } from 'react-redux';
import { setRisksAndPowerlinesOpacity } from '../store/actions/opacity';
import { updateRiskLevel } from '../store/actions/risk_level';

class ControlPanel extends React.Component {
  render() {
    const { dispatch, opacity, riskLevel } = this.props;

    return (
      <div className="panel is-left control_panel">
        <Logo />

        <div className="control_panel-input">
          <Switch
            firstLabel="Map"
            secondLabel="Satellite"
          />
        </div>

        <div className="control_panel-input">
          <label className="control_panel-input_label">
            Risk&amp;Lines Opacity
            <span className="control_panel-input_label_hint">
              {opacity * 100}%
            </span>

            <br />

            <input
              className="control-range"
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={opacity}
              onChange={(e) => dispatch(setRisksAndPowerlinesOpacity(e.target.value))}
            />
          </label>
        </div>

        <div className="control_panel-input">
          <label className="control_panel-input_label">
            Risk Level
            <span className="control_panel-input_label_hint">
              ≥ {riskLevel * 100}%
            </span>

            <br />

            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={riskLevel}
              onChange={(e) => dispatch(updateRiskLevel(e.target.value))}
            />
          </label>
        </div>

        <RisksList />
      </div>
    );
  }
}

const mapStateToControlPanelProps = (state) => ({
  mode: state.mode,
  opacity: state.opacity,
  riskLevel: state.riskLevel
});

export default connect(mapStateToControlPanelProps)(ControlPanel);
