import {
  SET_LOADING_FINISHED,
  INCREMENT_LOADING_PROGRESS,
  ADD_LOADING_ERROR
} from '../actionTypes';

export const setLoadingFinished = () => ({
  type: SET_LOADING_FINISHED
});

export const incrementLoadingProgress = (percent) => ({
  type: INCREMENT_LOADING_PROGRESS,
  payload: percent
});

export const addLoadingError = (errorMessage) => ({
  type: ADD_LOADING_ERROR,
  payload: errorMessage
});
