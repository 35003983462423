import {
  UPDATE_VIEWPORT,
  UPDATE_VIEWPORT_PITCH,
  MOVE_TO_COORDINATES
} from '../actionTypes';

const DEFAULT_TRANSITION_DURATION = 500;
const DEFAULT_MOVE_TO_ZOOM = 17.5;

export const updateViewport = (value) => ({
  type: UPDATE_VIEWPORT,
  payload: value
});

export const updateViewportPitch = (value) => ({
  type: UPDATE_VIEWPORT_PITCH,
  payload: value
});

export const moveToCoordinates = (lng, lat, zoom = DEFAULT_MOVE_TO_ZOOM) => ({
  type: MOVE_TO_COORDINATES,
  payload: {
    lng,
    lat,
    zoom,
    transitionDuration: DEFAULT_TRANSITION_DURATION
  }
});
