export const EXTRUSION_LAYER_DEFAULT_HEIGHT = ['*', 10, ['get', 'risk']];
export const EXTRUSION_LAYER = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-opacity': 1.0,
    'fill-extrusion-color': [
      'case',
      ['boolean', ['get', 'highlighted'], false], // 'false' is fallback value here, if highlighted is undefined
      '#f0f', // if highlighted (see styles/components/risks_list_item.sass)
      [       // else
        'step',
        ['get', 'risk'],
        // when you change these colors, be sure that they match
        // defined ones in styles/components/risks_list_item.sass
        '#fff41f',
        0.1, '#fde71f',
        0.2, '#f9d620',
        0.3, '#f6c022',
        0.4, '#f2a823',
        0.5, '#ed8e24',
        0.6, '#e97526',
        0.7, '#e55d26',
        0.8, '#e14828',
        0.9, '#dd3628',
        1.0, '#db2929'
      ]
    ],
    'fill-extrusion-height': EXTRUSION_LAYER_DEFAULT_HEIGHT
  }
};
