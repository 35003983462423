// filters the risks array by the given risk level
export function risksFilter(risksSource, riskLevel = 0) {
  return risksSource.features.map((v) => ({
    id: v.id,
    risk: v.properties.risk.toFixed(5),
    distance: v.properties.distance,
    lng: v.geometry.coordinates[0][0].flat()[0], // get first Polygon point longitude
    lat: v.geometry.coordinates[0][0].flat()[1], // get first Polygon point latitude
    selected: v.properties.selected
  })).filter(function (el) {
    return el.risk >= riskLevel;
  });
}
