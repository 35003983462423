import React from 'react';
import { connect } from 'react-redux';
import { moveToCoordinates } from '../store/actions/viewport';
import {
  addHighlightToRisk,
  removeHighlightFromRisk,
  addSelectionToRisk,
  removeSelectionFromRisk
} from '../store/actions/map';

class RisksListItem extends React.Component {
  handleOnClick = () => {
    const { dispatch, id, lng, lat, selected } = this.props;

    if (selected) {
      dispatch(removeSelectionFromRisk(id));
    }
    else {
      dispatch(addSelectionToRisk(id));
    }

    dispatch(moveToCoordinates(lng, lat));
  }

  handleOnMouseEnter = () => {
    const { dispatch, id } = this.props;

    dispatch(addHighlightToRisk(id));
  }

  handleOnMouseLeave = () => {
    const { dispatch, id, selected } = this.props;

    if (!selected) {
      dispatch(removeHighlightFromRisk(id));
    }
  }

  render() {
    const { id, risk, distance, selected } = this.props;

    const riskClass = Math.ceil(risk * 10); // 0, 10, 20, ..., 100
    const selectedClass = selected ? 'is-selected' : '';

    return (
      <li
        id={`risks_list_item-${id}`}
        className={`risks_list-row risks_list_item is-clickable is-${riskClass} ${selectedClass}`}
        onClick={this.handleOnClick}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <span>{id}</span>
        <span>{Math.round(risk * 100)}%</span>
        <span>{distance}</span>
      </li>
    );
  }
}

const mapStateToRisksListItemProps = () => ({});
export default connect(mapStateToRisksListItemProps)(RisksListItem);
