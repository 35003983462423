import { parseUrlParams } from './configureUrlManager';
import {
  addHighlightToRisk,
  addSelectionToRisk
} from './store/actions/map';

export default function configureRisksState(store) {
  const { selectedRisks } = parseUrlParams();

  if (typeof(selectedRisks) !== 'undefined') {
    const selectedRisksAsArray = [selectedRisks].flat();

    selectedRisksAsArray.forEach(riskID => {
      store.dispatch(addHighlightToRisk(riskID));
      store.dispatch(addSelectionToRisk(riskID));
    });
  }
}
