import {
  SWITCH_EXTRUSION_LAYER_ZERO_HEIGHT
} from '../actionTypes';

const initialState = false; // by default it has a formula 10 * risk level, see store/layerTypes.js

export default function zero_height(state = initialState, action) {
  switch(action.type) {
    case SWITCH_EXTRUSION_LAYER_ZERO_HEIGHT:
      return !state;

    default:
      return state;
  }
}
