import { SET_MAP_MODE } from '../actionTypes';

const initialState = 2; // 1 or 2 (Map or Satellite)

export default function mode(state = initialState, action) {
  switch (action.type) {
    case SET_MAP_MODE:
      const mode = parseInt(action.payload, 10);

      if (mode === 1 || mode === 2) {
        return mode;
      }
      else {
        return state;
      }
    default:
      return state;
  }
}
