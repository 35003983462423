import {
  SET_RISKS_AND_POWERLINES_LAYERS_OPACITY
} from '../actionTypes';

const initialState = 1.0;

export default function opacity(state = initialState, action) {
  switch (action.type) {
    case SET_RISKS_AND_POWERLINES_LAYERS_OPACITY:
      return action.payload;
    default:
      return state;
  }
}
