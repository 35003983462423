import React from 'react';
import { connect } from 'react-redux';
import RisksListItem from './RisksListItem';
import { risksFilter } from '../store/filters/map';
import { ReactComponent as DownloadIcon } from '../octicons/cloud-download.svg';

const DOWNLOAD_FILENAME = 'spacept_risks';
const SCROLL_TOP_OFFSET = 35; // px

class RisksList extends React.Component {
  constructor(props) {
    super(props);

    this.listRef = React.createRef();
  }

  downloadCSV = () => {
    const headers = {
      id: 'ID',
      risk: 'Risk (%)',
      distance: 'Proximity (m)',
      lng: 'Longitude',
      lat: 'Latitude'
    };

    exportCSVFile(headers, this.props.risks, DOWNLOAD_FILENAME);
  }

  downloadGeoJSON = () => {
    const { risks, rawRisks } = this.props;

    // determine which features from original array we need to export
    const filteredIDs = risks.map(risk => risk.id);
    const featuresToExport = rawRisks['features'].filter(risk =>
      filteredIDs.indexOf(risk.id) > -1
    );
    const exportData = {
      type: 'FeatureCollection',
      features: featuresToExport
    };

    exportGeoJSONFile(exportData, DOWNLOAD_FILENAME);
  };

  componentDidMount() {
    const { risks } = this.props;
    const selectedRisks = risks.filter(risk => risk.selected);

    if (selectedRisks.length > 0) {
      const firstSelectedRisk = selectedRisks[0];
      const riskItem = document.getElementById(`risks_list_item-${firstSelectedRisk.id}`);

      riskItem.scrollIntoView();
      // we need to adjust scroll when we scroll selected risk into view
      // to avoid situations when selected risk is hidden under header
      this.listRef.current.scrollTop -= SCROLL_TOP_OFFSET;
    }
  }

  render() {
    const { risks } = this.props;

    if (risks.length < 1) {
      return null;
    }

    return (
      <div className="risks_list" ref={this.listRef}>
        <ul className="risks_list-header">
          <li className="risks_list-row">
            <span title="Unique identificator of risk area">ID</span>
            <span title="Risk level (%)">Risk</span>
            <span title="Distance to trees (m)">Proximity</span>
          </li>
        </ul>

        <ul className="risks_list-body">
          {risks.map(risk => (
            <RisksListItem key={`risks_list_item-${risk.id}`} {...risk} />
          ))}
        </ul>

        <div className="risks_list-footer">
          <span className="risks_list-footer_info">
            Risks: {risks.length}
          </span>

          <button onClick={this.downloadCSV}>
            <DownloadIcon />&nbsp;CSV
          </button>

          <button onClick={this.downloadGeoJSON}>
            <DownloadIcon />&nbsp;JSON
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToRisksListProps = (state, _ownProps) => ({
  risks: risksFilter(state.map.sources.risks, state.riskLevel),
  rawRisks: state.map.sources.risks
});

export default connect(mapStateToRisksListProps)(RisksList);

// Source: https://codepen.io/danny_pule/pen/WRgqNx
function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';

    for (var index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

// Source: https://codepen.io/danny_pule/pen/WRgqNx
function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportFilename = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;'
  });

  downloadFile(blob, exportFilename);
}

// Source of idea: https://codepen.io/danny_pule/pen/WRgqNx
function exportGeoJSONFile(data, filename = 'export') {
  const exportFilename = filename + '.geojson';
  const geoJSON = JSON.stringify(data);

  const blob = new Blob([geoJSON], {
    type: 'application/json'
  });

  downloadFile(blob, exportFilename);
}

function downloadFile(blob, exportFilename) {
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportFilename)
  }
  else {
    let link = document.createElement('a');

    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
