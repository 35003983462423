import { combineReducers } from 'redux';

import loading from './loading';
import mode from './mode';
import riskLevel from './risk_level';
import map from './map';
import operative from './operative';
import viewport from './viewport';
import opacity from './opacity';
import zero_height from './zero_height';

export default combineReducers({
  loading,
  mode,
  riskLevel,
  map,
  operative,
  viewport,
  opacity,
  zero_height
});
