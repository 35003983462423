import { FlyToInterpolator } from 'react-map-gl';

import {
  UPDATE_VIEWPORT,
  UPDATE_VIEWPORT_PITCH,
  MOVE_TO_COORDINATES
} from '../actionTypes';

const initialState = {
  latitude: 0,
  longitude: 0,
  bearing: 0,
  pitch: 0,
  zoom: 15
};

export default function viewport(state = initialState, action) {
  switch (action.type) {
    case UPDATE_VIEWPORT:
      return action.payload;
    case UPDATE_VIEWPORT_PITCH:
      return {
        ...state,
        pitch: parseInt(action.payload, 10),
        transitionDuration: 300
      };
    case MOVE_TO_COORDINATES:
      const { lng, lat, transitionDuration, zoom } = action.payload;
      return {
        ...state,
        longitude: lng,
        latitude: lat,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration,
        zoom
      };
    default:
      return state;
  }
}
