import {
  SET_LOADING_FINISHED,
  INCREMENT_LOADING_PROGRESS,
  ADD_LOADING_ERROR
} from '../actionTypes';

const MAX_PROGRESS = 100;

const initialState = {
  finished: false,
  progress: 0,
  errors: []
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FINISHED:
      return {
        ...state,
        finished: true,
        progress: MAX_PROGRESS
      };

    case INCREMENT_LOADING_PROGRESS:
      let progress = state.progress + action.payload;

      // this might happen because of asyncronous nature of loading process
      if (progress > MAX_PROGRESS) {
        progress = MAX_PROGRESS;
      }

      return {
        ...state,
        progress
      };

    case ADD_LOADING_ERROR:
      const errors = state.errors;
      errors.push(action.payload);

      return {
        ...state,
        errors
      };

    default:
      return state;
  }
}
