import React from 'react';
import { connect } from 'react-redux';
import { updateViewportPitch } from '../store/actions/viewport';

const NO_PITCH = 0;
const PITCH_3D = 45; // max 60 degrees

class PitchModeToggle extends React.Component {
  toggleMode = () => {
    const { pitch, dispatch } = this.props;
    const newPitch = (pitch > NO_PITCH) ? NO_PITCH : PITCH_3D;

    dispatch(updateViewportPitch(newPitch));
  }

  render() {
    const { pitch } = this.props;

    const titleToSwitch = (pitch > NO_PITCH) ? '2D' : '3D';

    return (
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button
          className="mapboxgl-ctrl-icon pitch_mode_toggle"
          title={titleToSwitch}
          onClick={this.toggleMode}
        >
          {titleToSwitch}
        </button>
      </div>
    );
  }
}

const mapStateToPitchModeToggleProps = (state) => ({
  pitch: state.viewport.pitch
});

export default connect(mapStateToPitchModeToggleProps)(PitchModeToggle);
