import React from 'react';
import { connect } from 'react-redux';
import { switch_extrusion_layer_zero_height } from '../store/actions/zero_height';
import { ReactComponent as FoldUpIcon } from '../octicons/fold-up.svg';
import { ReactComponent as FoldDownIcon } from '../octicons/fold-down.svg';

class ZeroHeightModeToggle extends React.Component {
  toggleHeight = () => {
    const { dispatch } = this.props;

    dispatch(switch_extrusion_layer_zero_height());
  }

  render() {
    const { zero_height } = this.props;

    return (
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button
          className="mapboxgl-ctrl-icon pitch_mode_toggle"
          title="Switch between plain and 3D risks represetation"
          onClick={this.toggleHeight}
        >
          {zero_height ? <FoldUpIcon /> : <FoldDownIcon />}
        </button>
      </div>
    );
  }
}

const mapStateToZeroHeightModeToggleProps = (state) => ({
  zero_height: state.zero_height
});

export default connect(mapStateToZeroHeightModeToggleProps)(ZeroHeightModeToggle);
