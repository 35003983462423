import React from 'react';
import { connect } from 'react-redux';
import { setMapMode } from '../store/actions/mode';

class Switch extends React.Component {
  toggleMode = (e) => {
    this.props.dispatch(setMapMode(e.target.value));
  }

  render() {
    const { mode } = this.props;

    return (
      <form className="switch-field">
        <div className="switch-title">{this.props.title}</div>

        <input
          type="radio"
          id="switch_one"
          name="switchToggle"
          value={1}
          onChange={this.toggleMode}
          checked={mode === 1}
        />

        <label htmlFor="switch_one">{this.props.firstLabel}</label>

        <input
          type="radio"
          id="switch_two"
          name="switchToggle"
          value={2}
          onChange={this.toggleMode}
          checked={mode === 2}
        />

        <label htmlFor="switch_two">{this.props.secondLabel}</label>
      </form>
    );
  }
}

const mapStateToSwitchProps = (state) => ({
  mode: state.mode
});

export default connect(mapStateToSwitchProps)(Switch);
