import './styles/index.sass';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';

import urlUpdaterMiddleware from './configureUrlManager';
import configureStore from './configureStore';
import configureMapData from './configureMapData';
import configureRisksState from './configureRisksState';

const store = configureStore(urlUpdaterMiddleware);

// S3 bucket URL is defined inside configureMap(), also we provide method to
// finalize loading - risks state configuration should run on successful load
configureMapData(store, configureRisksState);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
