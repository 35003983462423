import {
  UPDATE_OPERATIVE_COORDINATES,
  UPDATE_OPERATIVE_RISK_LEVEL
} from '../actionTypes';

const initialState = {
  lngLat: [],
  riskLevel: 0
};

export default function operative(state = initialState, action) {
  switch (action.type) {
    case UPDATE_OPERATIVE_COORDINATES:
      return { ...state, lngLat: action.payload };
    case UPDATE_OPERATIVE_RISK_LEVEL:
      return { ...state, riskLevel: action.payload };
    default:
      return state;
  }
}
