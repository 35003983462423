import {
  LOAD_WIRES_SOURCE_DATA,
  LOAD_RISKS_SOURCE_DATA,
  LOAD_MAPBOX_SETTINGS,
  ADD_HIGHLIGHT_TO_RISK,
  REMOVE_HIGHLIGHT_FROM_RISK,
  ADD_SELECTION_TO_RISK,
  REMOVE_SELECTION_FROM_RISK
} from '../actionTypes';

export const loadWiresSourceData = (wiresData) => ({
  type: LOAD_WIRES_SOURCE_DATA,
  payload: wiresData
});

export const loadRisksSourceData = (risksData) => ({
  type: LOAD_RISKS_SOURCE_DATA,
  payload: risksData
});

export const loadMapboxSettings = (mapboxToken, mapStyle, tilesSourceUrl) => ({
  type: LOAD_MAPBOX_SETTINGS,
  payload: {
    mapboxToken,
    mapStyle,
    tilesSourceUrl
  }
});

export const addHighlightToRisk = (id) => ({
  type: ADD_HIGHLIGHT_TO_RISK,
  payload: {
    id
  }
});

export const removeHighlightFromRisk = (id) => ({
  type: REMOVE_HIGHLIGHT_FROM_RISK,
  payload: {
    id
  }
});

export const addSelectionToRisk = (id) => ({
  type: ADD_SELECTION_TO_RISK,
  payload: {
    id
  }
});

export const removeSelectionFromRisk = (id) => ({
  type: REMOVE_SELECTION_FROM_RISK,
  payload: {
    id
  }
});
