import { UPDATE_RISK_LEVEL } from '../actionTypes';

const initialState = 0;

export default function riskLevel(level = initialState, action) {
  switch (action.type) {
    case UPDATE_RISK_LEVEL:
      return parseFloat(action.payload);
    default:
      return level;
  }
}
